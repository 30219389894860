import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "pt-2 phone" }
const _hoisted_2 = {
  key: 0,
  class: "p-error"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputMask = _resolveComponent("InputMask")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_InputMask, {
        pattern: "[0-9]*",
        inputmode: "numeric",
        onChange: _ctx.setValues,
        placeholder: _ctx.placeholder,
        modelValue: _ctx.v$.name.$model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.name.$model) = $event)),
        mask: _ctx.mask,
        class: _normalizeClass({ 'p-invalid': _ctx.v$.name.$invalid && _ctx.isSubmitted }),
        autoClear: false
      }, null, 8, ["onChange", "placeholder", "modelValue", "mask", "class"])
    ]),
    (_ctx.v$.name.$invalid && _ctx.isSubmitted)
      ? (_openBlock(), _createElementBlock("small", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.validationLabel) + " ", 1),
          (_ctx.v$.name.Not4SameConsecutives.$invalid  && _ctx.isSubmitted)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, " No puede contener 4 números iguales consecutivos. "))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}