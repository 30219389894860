
  import {
    defineComponent,
    ref
  } from "vue";
  import Card from "primevue/card";
  import Button from "primevue/button";
  import TypeText from "../components/TypeText.vue";
  import TypePhone from "../components/TypePhone.vue";
  import TypeDropDown from "../components/TypeDropDown.vue";
  import TypeEmail from "../components/TypeEmail.vue";
  import TypeRadioButton from "../components/TypeRadioButton.vue";
  import * as LeadServices from "../api/helpers/Lead";
  import { UtelLeadService } from "../api/helpers/UtelLead"
  import * as FacebookServices from "../api/helpers/Facebook";
  import Questionnaire from "../models/Questionnaire";
  import * as QuestionnaireServices from "../api/helpers/Questionnaire";
  import _ from "underscore";
  import {
    useGtm
  } from "vue-gtm";
  import {
    getCurrentInstance
  } from "vue";
  export default defineComponent({
    name: "Home",
    components: {
      Card,
      Button,
      TypeText,
      TypePhone,
      TypeEmail,
      TypeDropDown,
      TypeRadioButton,
    },
    data() {
      return {
        submitted: false,
        isvalid: true,
        leadId: sessionStorage.getItem("leadId"),
        applicationId: sessionStorage.getItem("applicationId"),
        phoneLength: process.env.VUE_APP_PHONE_LENGTH,
        birthdate: new Date(),
        answers: {} as any,
        spinnerColor: process.env.VUE_APP_SPINNER_COLOR,
        spinnerTextColor: process.env.VUE_APP_SPINNER_TEXT_COLOR,
      };
    },
    setup(props) {
      const app = getCurrentInstance();
      const toastTime = app?.appContext.config.globalProperties.$toastTime;
      var loading = ref(true);
      var questionnaire = ref({});
      const questionnaires = ref({
        Questionnaire
      });
      const getQuestionnaire = async () => {
        questionnaire.value = await QuestionnaireServices.find().data;
      };
      var programsUnica = [
      { key: "AL-VI 004/2017", label: "Arte Digital y Multimedia" },
      { key: "AL-I 003/2017", label: "Comunicación Corporativa" },
      { key: "AL-IV 141/2017", label: "Marketing y Publicidad" },
      { key: "AL-VI 005/2017", label: "Medios Digitales" },
      { key: "ESLI20111401", label: "Psicología" },
      ];
    let programs = [
      { key: "20111176", label: "Administración" },
      { key: "20181986", label: "Administración de Empresas Turísticas" },
      { key: "20111178", label: "Administración de Negocios" },
      { key: "20111180", label: "Administración de Recursos Humanos" },
      {
        key: "20111179",
        label: "Administración de Tecnologías de Información",
      },
      {
        key: "20181988",
        label: "Administración de Ventas",
      },
      {
        key: "20111177",
        label: "Administración y Finanzas",
      },
      {
        key: "AL-VI 004/2017",
        label: "Arte Digital y Multimedia",
      },
      {
        key: "20181985",
        label: "Ciencias Políticas y Administración Pública",
      },
      {
        key: "20123183",
        label: "Comunicación",
      },
      {
        key: "AL-I 003/2017",
        label: "Comunicación Corporativa",
      },
      {
        key: "20123184",
        label: "Comunicación Digital",
      },
      {
        key: "20123185",
        label: "Comunicación Organizacional",
      },
      {
        key: "20111182",
        label: "Contaduría Pública",
      },
      {
        key: "20111181",
        label: "Contaduría y Finanzas",
      },
      {
        key: "20181596",
        label: "Criminología y Criminalística",
      },
      {
        key: "20160552",
        label: "Derecho",
      },
      {
        key: "20160550",
        label: "Derecho Empresarial",
      },
      {
        key: "20160551",
        label: "Derecho Internacional",
      },
      {
        key: "20181987",
        label: "Desarrollo Sustentable y Ecoturismo",
      },
      {
        key: "20111185",
        label: "Ingeniería en Sistemas Computacionales",
      },
      {
        key: "20111183",
        label: "Ingeniería Industrial",
      },
      {
        key: "20111184",
        label: "Ingeniería Industrial y Administración",
      },
      {
        key: "AL-IV 141/2017",
        label: "Marketing y Publicidad",
      },
      {
        key: "AL-VI 005/2017",
        label: "Medios Digitales",
      },
      {
        key: "20111186",
        label: "Mercadotecnia",
      },
      {
        key: "20111187",
        label: "Negocios Internacionales",
      },
      {
        key: "20111188",
        label: "Pedagogía",
      },
      {
        key: "ESLI20111401",
        label: "Psicología",
      },
      {
        key: "20111189",
        label: "Psicología Organizacional",
      },
      {
        key: "20231349",
        label: "Economía y Finanzas",
      },
      {
        key: "20231350",
        label: "Tecnología Educativa",
      },
    ];
    programs = _.chain([...programs, ...programsUnica])
      .map((program) => [program.label, program]) // Create [label, object] pairs
      .object() // Convert to object to ensure unique keys (labels)
      .values() // Get the unique objects
      .sortBy("label") // Sort by label
      .value(); // Extract the final array;
      return {
        questionnaire,
        questionnaires,
        getQuestionnaire,
        loading,
        toastTime,
        programs,
        programsUnica,
      };
    },
    mounted() {
      window.scrollTo(0, 0);
      FacebookServices.pageView();
      sessionStorage.clear();
      localStorage.clear();
      this.getQuestionnaire();
      this.questionnaires.Questionnaire.prototype = QuestionnaireServices.find().data;
      this.loading = false;
      const urlParams = new URLSearchParams(window.location.search);
      sessionStorage.setItem("SID", urlParams.get("SID") || "");
    },
    methods: {
      register() {
        this.submitted = true;
        this.isvalid = true;
        for (var x of this.questionnaires.Questionnaire.prototype.steps) {
          this.isvalid = !_.some(x.answers, (y) => y.valid == false) && this.isvalid;
          for (var y of x.answers) {
            if (!y.valid) {
              this.isvalid = false;
              break;
            }
            Object.assign(this.answers, {
              [y.key]: y.value
            });
          }
        }
        if (this.isvalid) {
          this.loading = true;
          this.submitted = false;
          if (this.leadId) this.updateLead();
          else this.createLead();
        }
      },
      setValue(obj: any) {
        if (this.questionnaires.Questionnaire.prototype.steps) {
          for (var x of this.questionnaires.Questionnaire.prototype.steps) {
            for (var y of x.answers) {
              if (y.key == obj.answerKey) {
                Object.assign(y, {
                  value: obj.value
                });
                Object.assign(y, {
                  valid: obj.valid
                });
                break;
              }
            }
          }
        }
      },
      createLead() {
        const steps = JSON.parse(sessionStorage.getItem("steps") || "{}");
        if (this.answers) {
          this.answers.termsAndConditions = true;
          this.answers.privacyNotice = true;
        this.answers.studiesField = this.programs.find((program)=>program.key === this.answers.interest)?.label;
        }
        LeadServices.create(this.answers).then(
          (res) => {
            const {
              data: {
                lead,
                application,
                newEmail
              },
            } = res.data;
            sessionStorage.setItem("newEmail", newEmail ? "1" : "0");
            sessionStorage.setItem("leadId", lead.id);
            sessionStorage.setItem("applicationId", application.id);
            sessionStorage.setItem("steps", JSON.stringify(Object.assign(steps, this.answers)));
            this.leadId = lead.id;
            this.applicationId = application.id;
            this.sendToUtel();
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: "error",
              summary: err,
              life: this.toastTime,
            });
          });
      },
      updateLead() {
        if (this.leadId) {
          const steps = JSON.parse(sessionStorage.getItem("steps") || "{}");
          this.answers.studiesField = this.answers.interest;
          LeadServices.update(this.answers, this.leadId).then(
            (res) => {
              const newEmail = res.data.data.newEmail;
              if (_.has(this.answers, "email")) sessionStorage.setItem("newEmail", newEmail ? "1" : "0");
              sessionStorage.setItem("steps", JSON.stringify(Object.assign(steps, this.answers)));
              this.sendToUtel();
            },
            (err) => {
              this.loading = false;
              this.$toast.add({
                severity: "error",
                summary: err,
                life: this.toastTime,
              });
            });
        }
      },
      sendToUtel() {
        UtelLeadService.createLead(this.answers)
        .then(
          (res) => {
            if (res.data.status) {
              const dataUtel = res.data
              sessionStorage.setItem("afresponse",JSON.stringify(res.data))
              this.to(res.data.data.id);
            } else {
              this.$toast.add({
                severity: "warn",
                summary: res.data.description,
                life: this.toastTime,
              });
            }
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: "error",
              summary: err,
              life: this.toastTime,
            });
          });
      },
      to(id: any) {
        this.$router.push({
          path: "resultados",
          query: { contactId: id},
        });
      },
    },
  });
