export function find() {
  return {
    code: "OK",
    message: "La operación se completó exitosamente.",
    data: {
      id: 1,
      name: "Utel",
      order: 0,
      active: true,
      steps: [
        {
          id: 1,
          name: "",
          details: "",
          order: 4,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 2,
              name: "Nombre(s)",
              key: "name",
              type: "string",
              component: "text",
            },
          ],
          filters: [],
        },
        {
          id: 2,
          name: "",
          details: "",
          order: 2,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 2,
              name: "Apellidos",
              key: "paternalSurname",
              type: "string",
              component: "text",
            },
          ],
          filters: [],
        },
        {
            id: 4,
            name: "",
            details: "",
            order: 4,
            step: 1,
            maxAnswers: 1,
            required: true,
            answers: [
              {
                id: 2,
                name: "Correo electrónico",
                key: "email",
                type: "string",
                component: "email",
              },
            ],
            filters: [],
        },
        {
          id: 6,
          name: "Teléfono móvil",
          details: "",
          order: 6,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 1,
              name: "Teléfono celular",
              key: "phone",
              type: "string",
              component: "phone",
            },
          ],
          filters: [],
        },
        {
          id: 7,
          name: "Programa de interés",
          details: "",
          order: 7,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 1,
              name: "Programa de interés",
              key: "interest",
              type: "string",
              component: "dropdown",
            },
          ],
          filters: [],
        },
        {
          id: 8,
          name: "",
          details: "",
          order: 8,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 3,
              name: "Acepto los términos y condiciones del servicio",
              key: "terms-privacy",
              type: "boolean",
              component: "radio",
            }
          ],
          filters: [],
        }
      ],
    },
  };
}
