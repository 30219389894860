
import { defineComponent, reactive, ref } from "vue";
import Dropdown from "primevue/dropdown";
import * as Catalog from "../api/helpers/Catalog";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  name: "TypeDropDown",
  components: {
    Dropdown,
  },
  props: {
    validationLabel: String,
    isRequired: Boolean,
    isSubmitted: Boolean,
    catalog: Array,
    answerKey: String,
    label: String
  },
  setup(props) {
    var loading = ref(false);
    var catalogData = ref({});
    // catalogData.value = props.catalog;
    // const getCatalog = async () => {
    //   if (props.catalog == "state") {
    //     await Catalog._find(
    //       `/state?system=${process.env.VUE_APP_SYSTEM_ID}&order=name`
    //     ).then((res) => {
    //       catalogData.value = res.data.data;
    //       loading.value = false;
    //     });
    //   }
    // };
    const f = reactive({
      name: undefined,
    });

    var rules;
    rules = props.isRequired
      ? (rules = {
          name: { required },
        })
      : (rules = {
          name: {},
        });

    const v$ = useVuelidate(rules, f);

    return { f, v$, catalogData, loading };
  },
  mounted() {
    var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
    if (
      typeof step === "object" &&
      this.answerKey &&
      Object.prototype.hasOwnProperty.call(step, this.answerKey)
    )
      this.v$.name.$model = step[this.answerKey];
    // this.getCatalog();
    this.setValues();
  },
  methods: {
    setValues() {
      this.$emit("getValue", {
        value: this.v$.name.$model,
        valid: !(!this.v$.name.$model && this.isRequired),
        answerKey: this.answerKey,
      });
    },
  },
});
