
import { defineComponent, reactive } from "vue";
import InputMask from "primevue/inputmask";
import { required, numeric, minLength, minValue } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { helpers } from 'vuelidate/lib/validators'

export default defineComponent({
  name: "TypePhone",
  components: {
    InputMask,
  },
  props: {
    label: String,
    validationLabel: String,
    isRequired: Boolean,
    isSubmitted: Boolean,
    answerKey: String,
    minLength: Number,
    placeholder: String
  },
  setup(props) {
    const f = reactive({
      name: "",
    });

    var rules;
    const Not4SameConsecutives = helpers.regex('Not4SameConsecutives', /^(?:(\d)(?!\1{3})){10}$/);
    var min = props.minLength || 10;
    var mask = "";
    var minVal = "2";
    for (var i = 0; i < min; i++) {
      mask += "9";
      if (minVal.length <= i)
        minVal += "0";
    }
    rules = props.isRequired
      ? (rules = {
          name: {
            numeric,
            minLength: minLength(min),
            minValue: minValue(minVal),
            Not4SameConsecutives,
            required,
          },
        })
      : (rules = {
          name: {
            numeric,
            minLength: minLength(min),
            minValue: minValue(minVal),
            Not4SameConsecutives,
          },
        });

    const v$ = useVuelidate(rules, f);
    return { f, v$, mask };
  },
  mounted() {
    var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
    if (
      typeof step === "object" &&
      this.answerKey &&
      Object.prototype.hasOwnProperty.call(step, this.answerKey)
    )
      this.v$.name.$model = step[this.answerKey];
    this.setValues();
  },
  methods: {
    setValues() {
      this.$emit("getValue", {
        value: this.v$.name.$model || undefined,
        valid:
          !(!this.v$.name.$model && this.isRequired) && !this.v$.name.$invalid,
        answerKey: this.answerKey,
      });
    },
  },
});
