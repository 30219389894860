import ScalahedInstance from "../Scalahed";

const ROUTES = {
  token: "/token/",
  lead: "/lead/",
};

export const UtelLeadService = {
  async getToken(username: string, password: string) {
    try {
      return await ScalahedInstance.post<{ refresh: string; access: string }>(
        ROUTES.token,
        { username, password }
      );
    } catch (error) {
      console.error("Error fetching token", error);
      throw new Error("Unable to fetch token");
    }
  },

  async createLead({
    name,
    paternalSurname,
    email,
    phone,
    interest,
  }: Record<string, any>) {
    try {
      const tokenResponse = await this.getToken(
        process.env.VUE_APP_UTEL_USERNAME,
        process.env.VUE_APP_UTEL_PASSWORD
      );
      const access = tokenResponse.data.access;

      return await ScalahedInstance.post(
        ROUTES.lead,
        {
          first_name: name,
          last_name: paternalSurname,
          email: email,
          phone: phone,
          program_of_interest: interest,
          experience_type: "Online",
          origin: "Leadgenios",
          country: "MX",
          utm_medium: process.env.VUE_APP_UTM_MEDIUM,
          utm_source: process.env.VUE_APP_UTM_SOURCE,
          utm_campaign: process.env.VUE_APP_UTM_CAMPAIGN,
          gclid: process.env.VUE_APP_GCLID,
        },
        { headers: { Authorization: `Bearer ${access}` } }
      );
    } catch (error) {
      console.error("Error creating lead", error);
      throw new Error("Unable to create lead");
    }
  },
};
