<template>
  <div>
    <Toast
      position="top-right"
      :breakpoints="{ '920px': { width: '100%', right: '0', left: '0' } }"
    />
    <Menubar :model="items">
      <template #start>
        <router-link :to="{ name: 'Home' }">
          <img
            alt="logo"
            src="./assets/images/utel-logo.webp"
            height="40"
            class="sm:pl-4 md:pl-6 lg:pl-6"
          />
        </router-link>
      </template>
    </Menubar>
    <router-view class="view" />
    <div class="bg-dark-gray py-4 row">
      <div class="col-sm-12 pb-40 text-center">
        <span class="text-white">
          2022 © Universidad Utel - Todos los derechos reservados.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Menubar from "primevue/menubar";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
export default {
  components: {
    Menubar: Menubar,
    Toast: Toast,
  },
  data() {
    return {
      name: process.env.VUE_APP_TITLE,
      fbPage: process.env.VUE_APP_FB_PAGE,
      items: [],
    };
  },
  setup() {
    const toast = useToast();
    const year = new Date().getUTCFullYear();
    return {
      toast,
      year,
    };
  },
};
</script>

<style>
.list-footer {
  list-style-type: none;
  text-align: left;
}

.list {
  color: #058fcc;
  font-size: 12px;
}

.p {
  color: white;
  font-size: 16px;
  margin: 1em;
}

.p-menubar-end {
  padding-right: 3rem;

}

.bg-black {
  background-color: #000;
}

i {
  font-size: 19px;
}

.view {
  width: 100%;
}

.p-menubar {
  position: fixed !important;
  z-index: 100;
  width: 100%;
  top: 0;
  background: #fff !important;
}

.p-menubar .p-menubar-button {
  position: absolute;
  right: 5%;
  display: none !important;
}

.p-menubar .p-menubar-button .pi {
  font-size: 2rem;
}

/* .p-menubar .p-menubar-root-list {
  position: absolute;
  left: 75%;
} */

@media (min-width: 1825px) {
  .footer {
    padding: 0 25rem;
  }
}

.p-fb {
  margin-top: 0;
}
.bg-dark-gray {
  background: #4E4E4E;
}
</style>
