import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: '/resultados',
    name: 'Results',
    component: () => import('../views/Results.vue')
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
  },
  linkActiveClass: 'active'
})
export default router
